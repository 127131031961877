import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import { RawOn, RawOff, Edit, Clear, ExpandMoreIcon } from '@mui/icons-material';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Parse from '../vendor/Parse'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import Textarea from '../components/Textarea';
const { useState } = React;
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const StoryCard = ({ post, actions, preview, id }) => {

    return <Card>
        <CardHeader
            avatar={
                <Avatar sx={{ bgcolor: preview ? "blue" : red[500] }} aria-label="recipe">
                    {preview ? "P" : "A"}
                </Avatar>
            }
            action={actions}
            title={post?.title}
            subheader={post?.createdAt?.toString()}
        />
        {
            post?.content && <Typography variant="body2" color="text.secondary" style={{ padding: '16px' }}>
                {ReactHtmlParser(post?.content)}
            </Typography>
        }
        {
            post?.image && <CardMedia
                component="img"
                height="294"
                image={post?.image}
                alt="Paella dish"
            />
        }
        {
            post?.colorText && <Box
                display="flex"
                height="294px"
                margin="0 0 16px 0"
                justifyContent="center"
                alignItems={"center"}
                sx={{

                    backgroundColor: post?.colorText?.bgColor,
                    color: 'white',
                    fontSize: '2rem'
                }}
            >
                <Box sx={{ maxWidth: '80%' }}>
                    {ReactHtmlParser(post?.colorText?.content)}
                </Box>
            </Box>
        }
        {
            post.video && <div class="youtube-video-container">
                <iframe width="560" height="315" src={post.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        }
    </Card>
}

export default function RecipeReviewCard({ list = [], isAuth, editPost, handleDelete, onPost }) {

    const [expanded, setExpanded] = React.useState(false);
    const [newPostPayload, setNewPostPayload] = useState({})
    const [editingPost, setEditingPost] = useState({})
    const [editRawMode, setEditRawMode] = useState(false)
    const [colorText, setColorText] = useState(false)
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            {
                list?.map((post) => <><Box id={`${post.itemId}`}></Box><StoryCard
                    post={post.data}
                    id={post.itemId}
                    actions={
                        isAuth && <>
                            <IconButton aria-label="settings" onClick={handleDelete}>
                                <Clear />
                            </IconButton>
                            <IconButton aria-label="settings" onClick={() => {
                                setNewPostPayload({
                                    _objectId: post.objectId,
                                    ...post.data
                                })
                                setEditRawMode(false)
                            }}>
                                <Edit />
                            </IconButton>
                        </>
                    }

                /></>)
            }

            <StoryCard
                
                preview
                post={newPostPayload}
            />
            {
                isAuth && <Card>
                    <CardHeader
                        action={<><IconButton aria-label="settings" onClick={() => setEditRawMode(true)}>
                            <RawOn />
                        </IconButton>
                            <IconButton aria-label="settings" onClick={() => setEditRawMode(false)}>
                                <RawOff />
                            </IconButton>
                        </>}
                    />
                    <CardContent>
                        <Typography variant="body2" color="text.secondary">
                            <Box>
                                {
                                    !editRawMode && <>
                                        <TextField
                                            value={newPostPayload.title}
                                            fullWidth onChange={(e) => {
                                                setNewPostPayload({
                                                    ...newPostPayload,
                                                    title: e.target.value,
                                                })
                                            }} />
                                        <Textarea
                                            value={newPostPayload.content}
                                            fullWidth onChange={(e) => {

                                                setNewPostPayload({
                                                    ...newPostPayload,
                                                    content: e.target.value
                                                })
                                            }} />
                                        <TextField
                                            value={newPostPayload.video}
                                            fullWidth placeholder="Video Url" onChange={(e) => {

                                                setNewPostPayload({
                                                    ...newPostPayload,
                                                    video: e.target.value
                                                })

                                            }} />
                                        {
                                            colorText && <TextField
                                                value={newPostPayload.video}
                                                fullWidth placeholder="Video Url" onChange={(e) => {

                                                    setNewPostPayload({
                                                        ...newPostPayload,
                                                        colorText: {
                                                            content: e.target.value,
                                                            bgColor: '#5AA9E6',
                                                        }
                                                    })

                                                }} />
                                        }
                                    </>
                                }
                                {
                                    editRawMode && <>
                                        <Textarea
                                            value={JSON.stringify(newPostPayload)}
                                            fullWidth onChange={(e) => {
                                                const json = JSON.parse(e.target.value);
                                                if (json) {
                                                    setNewPostPayload(json)
                                                }

                                            }} />
                                    </>
                                }

                            </Box>

                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        {
                            !colorText && <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}

                            >
                                Upload file
                                <VisuallyHiddenInput type="file" onChange={(event) => {

                                    const file = event.target.files[0];
                                    const reader = new FileReader();
                                    reader.onloadend = () => {
                                        setNewPostPayload({
                                            ...newPostPayload,
                                            image: reader.result
                                        })
                                    };
                                    reader.readAsDataURL(file);
                                }} />
                            </Button>
                        }


                        <Button onClick={() => setColorText(true)}>
                            Color Text
                        </Button>
                        <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"

                        >

                        </ExpandMore>
                        <Button onClick={() => onPost && onPost(newPostPayload)}>
                            Post
                        </Button>
                    </CardActions>
                </Card>
            }
        </>

    );
}